import React from 'react';
//import toast from 'react-hot-toast';
import './style.scss';

const ContactForm = () => {
  // const [formData, setFormData] = useState({
  //   name: '',
  //   lastname: '',
  //   email: '',
  //   phone: '',
  // });
  // const [isSent, setIsSent] = useState(false);

  // const handleChange = e => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const encodeForm = data => {
  //   return Object.keys(data)
  //     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
  //     .join('&');
  // };

  // const validateForm = () => {
  //   const { name, lastname, email, phone } = formData;
  //   if (!name || !lastname || !email || !phone) {
  //     toast.error('Prosím, vyplňte všetky polia.');
  //     return false;
  //   }
  //
  //   if (!/^[a-zA-Z]+$/.test(name)) {
  //     toast.error('Meno môže obsahovať iba písmená.');
  //     return false;
  //   }
  //
  //   if (!/^[a-zA-Z]+$/.test(lastname)) {
  //     toast.error('Priezvisko môže obsahovať iba písmená.');
  //     return false;
  //   }
  //
  //   if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
  //     toast.error('Zadajte platný email.');
  //     return false;
  //   }
  //
  //   if (!/^(\+)?[0-9 ]{10,15}$/.test(phone)) {
  //     toast.error('Zadajte platné telefónne číslo.');
  //     return false;
  //   }
  //   return true;
  // };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //
  //   if (!validateForm()) {
  //     return;
  //   }
  //
  //   fetch('/', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     body: encodeForm({ 'form-name': 'contact', ...formData }),
  //   })
  //     .then(() => {
  //       //toast.success('Ďakujeme za Váš záujem, čoskoro Vás budeme kontaktovať.');
  //       setFormData({
  //         name: '',
  //         lastname: '',
  //         email: '',
  //         phone: '',
  //       });
  //       // setIsSent(true);
  //     })
  //     //.catch(error => toast.error(error));
  //     .catch(() => toast.error('Niečo sa pokazilo, skúste to znova.'));
  // };

  // const formBeforeSent = isSent => {
  //   return (
  //     <div className={`contact-form__content ${isSent && 'contact-form__content--hidden'}`}>
  //       <h2>Kontaktujte nás</h2>
  //       <p>Poskytnite nám vaše kontaktné údaje, aby vás mohli naši kolegovia z finančného oddelenia čo najskôr kontaktovať.</p>;
  //       <div className="contact-form__inputs">
  //         <div className="contact-form__input-field float-group">
  //           <input type="text" name="name" placeholder=" " required value={formData.name} onChange={handleChange} />
  //           <label>
  //             Meno<span>*</span>
  //           </label>
  //         </div>
  //         <div className="contact-form__input-field float-group">
  //           <input type="text" name="lastname" placeholder=" " required value={formData.lastname} onChange={handleChange} />
  //           <label>
  //             Priezvisko<span>*</span>
  //           </label>
  //         </div>
  //         <div className="contact-form__input-field float-group">
  //           <input type="email" placeholder=" " name="email" required value={formData.email} onChange={handleChange} />
  //           <label>
  //             Email<span>*</span>
  //           </label>
  //         </div>
  //         <div className="contact-form__input-field float-group">
  //           <input type="tel" name="phone" placeholder=" " required value={formData.phone} onChange={handleChange} />
  //           <label>
  //             Telefónne číslo<span>*</span>
  //           </label>
  //         </div>
  //         <label className="checkbox">
  //           <input type="checkbox" className="checkbox__input" required={true} />
  //           <span className="checkbox__custom"></span>
  //           <span className="checkbox__label">
  //             Súhlasím s{' '}
  //             <a href="https://dajanarodriguez.sk/podmienky" className="checkbox__label-detail">
  //               obchodnými podmienkami.
  //             </a>
  //             <br />
  //             <a href="https://dajanarodriguez.sk/spracovanie-osobnych-udajov">
  //               <small>Informácie o spracovaní osobných údajov</small>
  //             </a>
  //           </span>
  //         </label>
  //       </div>
  //       ;
  //       <button type="submit" className="button" style={{ backgroundColor: '#000', margin: '32px auto 0', fontSize: '18px' }}>
  //         <div className="button__circles">
  //           <span></span>
  //           <span></span>
  //         </div>
  //         Odoslať
  //       </button>
  //     </div>
  //   );
  // };

  const formAfterSent = isSent => {
    return (
      <div className={`contact-form__thanks ${isSent && 'contact-form__thanks--active'}`}>
        <h2>Informácie</h2>
        <p>Pre viac info nás kontaktujte na dlhopisy@dajanarodriguez.com</p>
      </div>
    );
  };

  return (
    <section className="contact-form">
      <form name="contact">
        <div className="circles">
          <span></span>
          <span></span>
        </div>
        {/*{formBeforeSent(isSent)}*/}
        {formAfterSent(true)}
      </form>
    </section>
  );
};

export default ContactForm;
